:root:root,
:root {
  --adm-color-primary: #a0b400 !important;
}
body {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 28px !important;
  background-color: var(--adm-color-primary) !important;
}
.btn:hover {
  cursor: pointer !important;
  color: black;
}
.btn:active {
  text-decoration: underline;
}
.disabled,
.disabled:hover,
.disabled:focus {
  cursor: not-allowed !important;
  text-decoration: none !important;
  color: black;
}
aside {
  height: 100vh;
  overflow: scroll;
}
footer {
  text-align: center;
}
.ant-layout-footer {
  color: gray;
}
main {
  min-height: calc(100vh - 70px - 1px - 45px) !important;
  /* fill whole screen*/
  padding: 24px;
  padding-bottom: 0;
}
.scrollable {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
}
aside,
section,
main,
.scrollable {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: thin;
  /* Firefox */
}
aside::-webkit-scrollbar,
section::-webkit-scrollbar,
main::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Chrome, Safari and Opera */
}
.cursor-pointer:hover {
  cursor: pointer;
}
.ant-notification {
  z-index: 9999;
}
.ant-table-body {
  overflow-y: auto !important;
  overflow-x: auto !important;
}
.ant-table-wrapper,
.ant-table-wrapper > *,
.ant-table-wrapper > * > *,
.ant-table,
.ant-table-footer {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  padding: 0 !important;
  background-color: transparent !important;
}
[data-slate-editor] > * + * {
  margin-top: -0.75em;
}
[report-editor] > * + * {
  margin-top: 1em;
}
.ant-menu-item {
  font-size: xx-large !important;
  margin-bottom: 24px !important;
  align-content: center;
}
.clickable {
  border: 1px solid transparent !important;
  cursor: pointer;
}
.clickable:hover {
  border: 1px solid gray !important;
  border-radius: 8px !important;
}
.clickable:active {
  border: 1px solid gray !important;
  cursor: grabbing !important;
}
.ant-space-item:has(#selfCheckInList) {
  flex-basis: 70px !important;
  flex-grow: 1;
  overflow: hidden;
}
.action-delete {
  color: #ff4d4f !important;
  border-color: #ff4d4f !important;
  background: #fff;
}
.action-delete:hover {
  scale: 130%;
}
.ant-card-actions::before {
  display: block!important;
}
